<template>
  <div class="flex md12">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <div>
      <div style="margin: 0 auto;">
        <div class="row" style="justify-content: center;">
          <div :style="topScaleRatio">
            <div class="company_logo"><img src="@/assets/infyn.png" width="130" height="130" style="margin-left: 5rem !important;" /></div>
            <div class="left_screen_background">
              <img class="image1" src="@/assets/tv.png" />
              <video
                v-if="loaderHide"
                class="pageLoader"
                src="@/assets/FinalVideoLoader.mp4"
                autoplay
                loop
                muted
                playsinline
              />
              <div class="transparentImage"></div>
              <video
                v-if="!videoChanged"
                class="image2"
                :src="homeVideoPath"
                autoplay
                loop
                muted
                playsinline
              />
              <video
                v-if="videoChanged"
                class="image2"
                src="@/assets/FinalVideoLoader.mp4"
                autoplay 
                loop
                muted
                playsinline
              />
              <img class="menuLogo" src="@/assets/menu.png" />
              <img class="tvInCompnayLogo" :src="brandLogo" />
              <img class="tvInBrandLogo" src="@/assets/powered_by_infynect.png" />
              <span class="guest_name"><b>{{guest_name}}</b></span>
              <span class="WelcomeMsg">{{welcomeMsg}}</span>
              <img class="kitchen_label" src="@/assets/label.png" />
              <img class="kitchenLogo" src="@/assets/cooking.png" />
              <img class="timeLogo" src="@/assets/time.png" />
              <span class="timetextTag">Time</span>
              <span class="timeTag">{{on_time}}</span>
              <span class="kitchentextTag">Kitchen Closes</span>
              <span class="kitchentimeTag">{{kitchen_close}}</span>
              <img class="lineset" src="@/assets/line.png" />
              <span class="labelTag">Food</span>
              <div class="foodlisttag">
                <div>
                  <a v-for="food in foodLists">
                    <img class="imageTile ml-2" :src="food.image_path" />
                  </a>
                </div>
              </div>
              <span class="locationLabel">Places you must visit</span>
              <div class="locationlisttag">
                <div>
                  <a v-for="location in locationLists">
                    <img class="imageTile ml-2" :src="location.image_path" />
                  </a>
                </div>
              </div>
              <span class="brandText">Create an immersive in-room experience with Hepi</span>
              <span class="brandText2">make your guest feel extra special</span>

              <img src="@/assets/bottomMenuLine.png" class="bh" />
              <img src="@/assets/bh1.png" class="bh1" />
              <img src="@/assets/fh3.png" class="bh2" @click="redirect('food')" style="cursor: pointer;"/>
              <img src="@/assets/lh3.png" class="bh3" @click="redirect('location')" style="cursor: pointer;"/>
              <img src="@/assets/sh3.png" class="bh4" @click="redirect('service')" style="cursor: pointer;"/>
              <img src="@/assets/ih2.png" class="bh5" @click="redirect('integration')" style="cursor: pointer;"/>
              <span class="bt1">Branding</span>
              <span class="bt2">Food Menu</span>
              <span class="bt3">Nearby Places</span>
              <span class="bt4">Services</span>
              <span class="bt5">Integration</span>
            </div>
          </div>

          <div :style="bottomScaleRatio">
            <div class="bcardView">
              <img class="card_header" src="@/assets/card_rectangle.png"/>
              <center>
                <span class="cardheaderline">Insert your logo and view the solution with your brand.</span>
              </center>
              <img class="btopleftCard" src="@/assets/cardtopleft.png" />
              <img class="bbottomrightCard" src="@/assets/cardbottomright.png" />
              <va-card class="binsideCard">
                <div>
                  <img class="videobutton1" src="@/assets/belowLabel.png" />
                  <label class="videobutton2">Hotel Video</label>
                  <button class="logobuttonlistner" @click="onClickHandler('video')">Upload Video</button>
                </div>
                <div class="mt-3">
                  <img class="videobutton1" src="@/assets/belowLabel.png" />
                  <label class="videobutton2">Hotel Logo</label>
                  <button class="logobuttonlistner" @click="onClickHandler('logo')">Upload Image (512 X 512)</button>
                </div>
                <div class="mt-3">
                  <img class="msgbutton1" style="width: 131px;" src="@/assets/belowLabel.png" />
                  <label class="msgbutton2">Welcome Message</label>
                  <input class="b_input_tag" v-model="welcome_message" @focusout="homeCustomize('home_wel')" />
                </div>
                <div class="mt-3">
                  <img class="msgbutton1" style="width: 89px;" src="@/assets/belowLabel.png" />
                  <label class="msgbutton2">Description</label>
                  <input class="b_input_tag" v-model="description" @focusout="homeCustomize('home_des')" />
                </div>
                <div class="mt-3">
                  <img class="msgbutton1" style="width: 115px;" src="@/assets/belowLabel.png" />
                  <label class="msgbutton2">Kitchen Closing</label>
                  <input class="b_input_tag" v-model="kitchen_close" @focusout="homeCustomize('home_close')" />
                </div>
              </va-card>
            </div>
            <div style="margin-top: 56px; margin-left: 127px; margin-bottom: 50px;">
              <button class="bnext" @click="redirect('dashboard')"><img src="@/assets/leftarrow.png" height="12" style="position: absolute; margin-left: -20px; margin-top: 4px;" />Go Back</button>
              <button class="bback ml-3" @click="redirect('food')">Food Menu<img src="@/assets/rightarrow.png" height="12" style="position: absolute; margin-left: 6px; margin-top: 4px;" /></button>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import vueResource from 'vue-resource';
  import config from '../../i18n/en.json';
  import ScreenSizeDetector from 'screen-size-detector';
  import MobileDetect from 'mobile-detect';
  Vue.use(vueResource)

  export default {
    created () {
      var screenSize=new ScreenSizeDetector().width;
      var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
      this.topScaleRatio="zoom: "+scaleRatioNumber+";";
      this.bottomScaleRatio="zoom: "+(scaleRatioNumber!=1 ? scaleRatioNumber+0.2 : scaleRatioNumber)+";";
      this.loaderHide = true;
      var md = new MobileDetect(window.navigator.userAgent);
      this.device = (md.phone()) ? true : false;
      const myTimer = () => {
        var date = new Date(), 
            noon = (date.getHours() >= 12) ? 'PM' : 'AM',
            hour = ((date.getHours()) > 12) ? ('0'+(date.getHours() - 12)) : date.getHours(),
            mins = ((date.getMinutes()) < 10) ? ('0'+(date.getMinutes())) : date.getMinutes();
        this.on_time = hour + ":" + mins + " " + noon;
      }
      setInterval(myTimer, 1000);
      setTimeout(() => { this.loader = false }, 2000);
      this.getUsers();
      this.getBrandings();
      this.getContents();
    },
    data () {
      return {
        device: false,
        welcomeMsg: '',
        guest_name: '',
        foodLists: [],
        locationLists: [],
        on_time: '',
        hotel_logo: [],
        hotel_video: '',
        welcome_message: '',
        description: '',
        kitchen_close: '',
        loader: true,
        homeVideoPath: '',
        brandLogo: '',
        homeSetting_id: '',
        user_id: '',
        videoChanged: false,
        loaderHide: true,
        topScaleRatio:'',
        bottomScaleRatio:''
      }
    },
    mounted: function () {
      window.setInterval(() => {
        var md = new MobileDetect(window.navigator.userAgent);
        this.device = (md.phone()) ? true : false;
      }, 2000)
    },
    methods: {
      redirect(type) {
        this.$router.push({ name: type });
      },
      getContents() {
        this.$http.get(config.menu.host + 'content/food').then(responseData => {
          this.foodLists = responseData.body;
          this.$http.get(config.menu.host + 'content/location').then(responseData => {
            this.locationLists = responseData.body;
            setTimeout(() => {
              this.loaderHide = false;
            }, 2000);
          });
        });
      },
      getUsers() {
        this.$http.get(config.menu.host + 'content/user').then(user => {
          this.guest_name = 'HI '+user.body[0].full_name.toUpperCase();
          this.welcome_message = user.body[0].full_name.toUpperCase();
          this.user_id = user.body[0].user_id;
        });
      },
      getBrandings() {
        this.$http.get(config.menu.host + 'content/branding').then(responseBrand => {
          this.welcomeMsg = responseBrand.body[0].intro_message;
          this.videoChanged = false;
          this.homeVideoPath = responseBrand.body[0].video_path;
          this.homeSetting_id = responseBrand.body[0].homesetting_id;
          this.description = responseBrand.body[0].intro_message;
          this.kitchen_close = responseBrand.body[0].kitchen_close;
          this.brandLogo = responseBrand.body[0].logo_path;
        });
      },
      onClickHandler(ev) {
        var el = window._protected_reference = document.createElement("INPUT");
        el.type = "file";
        el.addEventListener('change', () => { 
          if(ev == 'logo'){
            var file_name = el.files[0].name.split('.').pop();
            if(file_name.toLowerCase() !== 'mp4'){
              var payload = new FormData();
              payload.append('image', el.files[0]);
              payload.append('id', this.homeSetting_id);

              this.$http.put(config.menu.host + 'content/home_image', payload).then(response => { 
                this.getBrandings();
              })  
            }else{
              return Vue.notify({text:'Wrong File Format uploaded.', type:'error'});
            }
          }else{
            var file_name = el.files[0].name.split('.').pop();
            if(file_name.toLowerCase() === 'mp4'){
              this.videoChanged = true;
              var payload = new FormData();
              payload.append('video', el.files[0]);
              payload.append('id', this.homeSetting_id);

              this.$http.put(config.menu.host + 'content/home_video', payload).then(response => { 
                this.getBrandings();
              }) 
            }else{
              return Vue.notify({text:'Wrong File Format uploaded.', type:'error'});
            }
          }
        });
        el.click();
      },
      homeCustomize(input) {
        if(input == 'home_wel'){
          var payload = {
            'full_name':this.welcome_message,
            'user_id':this.user_id
          };

          this.$http.put(config.menu.host + 'content/user', payload).then(response => {
            this.getUsers();
          });
        };
        if(input == 'home_des'){
          var payload = {}
          payload['intro_message'] = this.description;
          payload['id'] = this.homeSetting_id;

          this.$http.put(config.menu.host + 'content/home_image', payload).then(response => {
            this.getBrandings();
          });
        };
        if(input == 'home_close'){
          var payload = {}
          payload['kitchen_close'] = this.kitchen_close;
          payload['id'] = this.homeSetting_id;

          this.$http.put(config.menu.host + 'content/home_image', payload).then(response => {
            this.getBrandings();
          });
        };
      },
    }
  }
</script>

<style>
  body {
    background-image: url("../../assets/new_repeat.jpg");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
  }

  button {
    cursor: pointer;
  }

  .foodlisttag::-webkit-scrollbar {
    display: none;
  }

  .company_logo {
    position: absolute;
  }

  .left_screen_background {
    background-image: url("../../assets/circlebg.png");
    position: relative;
    background-position: center 1px;
    background-repeat: no-repeat;
    background-size: 679px;
    text-align: center;
    height: 670px;
    width: 800px;
  }

  .pageLoader {
    position: absolute;
    z-index: 10;
    left: 77px;
    height: 358px;
    width: 668px;
    top: 133px;
  }

  .parent {
    position: relative;
  }

  .image1 {
    width: 746px;
    margin-top: -38px;
  }

  .image2 {
    position: absolute;
    top: 136px;
    left: 180px;
    width: 535px;
    height: 304px;
  }

  .transparentImage {
    position: absolute;
    top: 133px;
    left: 119px;
    background-image: url("../../assets/transparent11.png");
    width: 597px;
    height: 355px;
    z-index: 3;
  }

  .tvInCompnayLogo {
    position: absolute;
    top: 145px;
    left: 125px;
    z-index: 4;
    width: 55px;
    height: 55px;
  }

  .tvInBrandLogo {
    position: absolute;
    width: 50px;
    height: 47px;
    z-index: 4;
    left: 183px;
    top: 150px;
  }

  .menuLogo {
    position: absolute;
    top: -3px;
    left: 234px;
    z-index: 5;
    width: 323px;
  }

  .guest_name {
    position: absolute;
    top: 207px;
    left: 130px;
    z-index: 3;
    height: 75px;
    color: #d96516;
    text-size-adjust: 50%;
  }

  .WelcomeMsg {
    position: absolute;
    top: 232px;
    left: 130px;
    z-index: 3;
    width: 192px;
    font-size: 12px;
    color: #cccccc;
    text-align: left;
    white-space: normal;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-size-adjust: 50%;
  }

  .kitchen_label {
    position: absolute;
    top: 263px;
    left: 121px;
    z-index: 7;
    width: 180px;
    height: 123px;
  }

  .kitchenLogo {
    position: absolute;
    top: 291px;
    left: 241px;
    z-index: 8;
    width: 21px;
  }

  .kitchentextTag {
    position: absolute;
    top: 315px;
    left: 225px;
    z-index: 8;
    font-size: 10px;
    color: #cccccc;
    text-size-adjust: 50%;
  }

  .kitchentimeTag {
    position: absolute;
    top: 328px;
    left: 232px;
    z-index: 9;
    font-size: 11px;
    color: #d96516;
    font-weight: 600;
    text-size-adjust: 50%;
  }

  .timeLogo {
    position: absolute;
    top: 292px;
    left: 156px;
    z-index: 8;
    width: 20px;
  }

  .lineset {
    position: absolute;
    top: 316px;
    left: 209px;
    height: 30px;
    z-index: 8;
  }

  .timetextTag {
    position: absolute;
    top: 313px;
    left: 156px;
    z-index: 8;
    font-size: 10px;
    color: #cccccc;
    text-size-adjust: 50%;
  }

  .timeTag {
    position: absolute;
    top: 328px;
    left: 146px;
    z-index: 9;
    font-size: 11px;
    color: #d96516;
    font-weight: 600;
    text-size-adjust: 50%;
  }

  .labelTag {
    position: absolute;
    top: 354px;
    left: 130px;
    z-index: 9;
    font-size: 12px;
    text-size-adjust: 50%;
    color: #ffffff;
  }

  .foodlisttag {
    position: absolute;
    top: 376px;
    left: 119px;
    z-index: 9;
    overflow-x: scroll;
    scrollbar-width: none;
    white-space: nowrap;
    width: 597px;
  }

  .imageTile {
    border-radius: 7px;
    width: 88px;
    height: 51px;
  }

  .locationLabel {
    position: absolute;
    top: 439px;
    left: 129px;
    z-index: 9;
    font-size: 12px;
    color: #ffffff;
    text-size-adjust: 50%;
  }

  .locationlisttag {
    position: absolute;
    top: 461px;
    left: 119px;
    z-index: 9;
    overflow: hidden;
    scrollbar-width: none;
    white-space: nowrap;
    width: 597px;
    height: 30px;
  }

  .right_screen_background {
    position: fixed;
  }

  .bcardView {
    position: relative;
    width: 400px;
    background-color: #343434;
    height: 481px;
    z-index: 1;
    margin-top: 135px;
  }

  .card_header {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 398px;
    height: 65px;
  }

  .cardheaderline {
    position: absolute;
    top: 9px;
    left: 60px;
    width: 279px;
    font-size: 16px;
    color: white;
    text-size-adjust: 80%;
  }

  .binsideCard {
    top: 88px;
    left: 15px;
    width: 370px;
    height: 372px;
    border-radius: 14px;
    background-color: #232222;
  }

  .btopleftCard {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 80px;
    height: 70px;
  }

  .bbottomrightCard {
    position: absolute;
    top: 420px;
    left: 329px;
    width: 80px;
    height: 70px;
  }

  .b_input_tag {
    background-color: #232222;
    border: 1px solid #3f3f3f;
    border-radius: 8px;
    height: 50px;
    width: 330px;
    text-indent: 23px;
    text-align: left;
    color: white;
    text-size-adjust: 50%;
  }

  .brandText {
    font-size: 23px;
    color: white;
    font-weight: 600;
    left: 139px;
    top: 548px;
    position: absolute;
    text-size-adjust: 50%;
  }

  .brandText2 {
    font-size: 23px;
    color: #8b8e94;
    left: 231px;
    top: 577px;
    position: absolute;
    text-size-adjust: 50%;
  }

  .bh {
    left: 150px;
    top: 671px;
    position: absolute;
    width: 487px;
  }

  .bh1 {
    left: 134px;
    top: 630px;
    position: absolute;
    height: 83px;
  }

  .bh2 {
    left: 245px;
    top: 631px;
    position: absolute;
    height: 83px;
  }

  .bh3 {
    left: 359px;
    top: 631px;
    position: absolute;
    height: 83px;
  }

  .bh4 {
    left: 477px;
    top: 631px;
    position: absolute;
    height: 83px;
  }

  .bh5 {
    left: 591px;
    top: 631px;
    position: absolute;
    height: 83px;
  }

  .bt1 {
    left: 153px;
    top: 716px;
    position: absolute;
    font-size: 12px;
    color: white;
    text-size-adjust: 50%;
  }

  .bt2 {
    left: 259px;
    top: 716px;
    position: absolute;
    font-size: 12px;
    color: #343434;
    text-size-adjust: 50%;
  }

  .bt3 {
    left: 367px;
    top: 716px;
    position: absolute;
    font-size: 12px;
    color: #343434;
    text-size-adjust: 50%;
  }

  .bt4 {
    left: 496px;
    top: 718px;
    position: absolute;
    font-size: 12px;
    color: #343434;
    text-size-adjust: 50%;
  }

  .bt5 {
    left: 607px;
    top: 718px;
    position: absolute;
    font-size: 12px;
    color: #343434;
    text-size-adjust: 50%;
  }

  .bback {
    padding: 8px 30px;
    font-size: 16px;
    border-radius: 4px;
    left: 1211px;
    top: 719px;
    background-color: #f8741a;
    border: #f8741a;
    color: white;
    text-size-adjust: 72%;
  }

  .bnext {
    padding: 8px 34px;
    font-size: 16px;
    color: white;
    border-radius: 4px;
    left: 1071px;
    top: 719px;
    background-color: #272727;
    border: #272727;
    text-size-adjust: 72%;
  }

  .bl {
    left: 1087px;
    top: 730px;
    position: absolute;
    height: 15px;
  }

  .br {
    left: 1320px;
    top: 730px;
    position: absolute;
    height: 15px;
  }

  .logobuttonlistner {
    width: 330px;
    height: 50px;
    border-radius: 8px;
    background-color: #232222;
    border: 1px solid #3f3f3f;
    color: #343434;
    text-size-adjust: 50%;
  }

  .videobutton1 {
    position: absolute;
    width: 83px;
    margin-top: -10px;
    margin-left: 16px;
    color: #2d2c2c;
  }

  .videobutton2 {
    position: absolute;
    margin-left: 19px;
    margin-top: -13px;
    color: #676767;
    text-size-adjust: 72%;
  }

  .msgbutton1 {
    position: absolute;
    height: 24px;
    margin-top: -13px;
    margin-left: 18px;
  }

  .msgbutton2 {
    position: absolute;
    margin-left: 22px;
    margin-top: -12px;
    color: #676767;
    text-size-adjust: 70%;
  }

  .loader {
    position: absolute;
    height: 365px;
    width: 632px;
    z-index: 10;
    left: 138px;
    top: 164px;
  }
</style>
